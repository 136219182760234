import { Routes, Route, Link } from "react-router-dom";

import Home from "./pages/Home";
// import NotFound from "./pages/NotFound";

function About() {
  return (
    <>
      <main>
        <h2>Who am I?</h2>
        <p>That feels like an existential question, don't you think?</p>
      </main>
      <nav>
        <Link to="/">Home</Link>
      </nav>
    </>
  );
}

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="about" element={<About />} />
    </Routes>
  );
};

export default Router;
