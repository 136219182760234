import { ReactNode } from "react";
import {
  Box,
  // Button,
  Container,
  Stack,
  SimpleGrid,
  Image,
  Text,
  Link,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";

import { URL_LOGO } from "../../constants";

// import { FaInstagram } from "react-icons/fa";

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

const Footer = () => {
  return (
    <Box>
      <Container as={Stack} maxW={"6xl"} py={10}>
        <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={8}>
          <Stack align={"flex-start"}>
            <ListHeader>Links</ListHeader>
            <Link href={"mailto:metamodernchurch@gmail.com"}>Contact Me</Link>
          </Stack>

          {/* 
          <Stack align={"flex-start"}>
            <ListHeader>Social</ListHeader>
            <Button
              cursor={"pointer"}
              as={"a"}
              href={"https://www.instagram.com/metamodernchurch"}
              target="_blank"
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              mt="2"
              mr="2"
            >
              <FaInstagram color="#1da1f2" size={20} />
              <Text ml={2}>Instagram</Text>
            </Button>
          </Stack> */}

          <Stack align={"flex-start"}>
            <ListHeader>Place for finding purpose</ListHeader>
            <ListHeader>Through Art and Storytelling</ListHeader>
            <ListHeader>Manifest Your Destiny</ListHeader>
          </Stack>
        </SimpleGrid>
      </Container>

      <Box py={10}>
        <Flex
          align={"center"}
          _before={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: useColorModeValue("gray.200", "gray.700"),
            flexGrow: 1,
            mr: 8,
          }}
          _after={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: useColorModeValue("gray.200", "gray.700"),
            flexGrow: 1,
            ml: 8,
          }}
        >
          <Image
            boxSize="50px"
            objectFit="cover"
            borderRadius="full"
            src={URL_LOGO}
            alt=""
          />
        </Flex>
        <Text pt={6} fontSize={"sm"} textAlign={"center"}>
          © 2022 Meta Modern Church
        </Text>
      </Box>
    </Box>
  );
};

export default Footer;
