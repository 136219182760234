import Header from "./components/Header";
import Footer from "./components/Footer";

import theme from "./themes/default";

import { ChakraProvider } from "@chakra-ui/react";

import "./App.css";

import { BrowserRouter } from "react-router-dom";

import Router from "./routes";

const App = (props: any) => {
  return (
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <Main />
      </ChakraProvider>
    </BrowserRouter>
  );
};

const Main = () => {
  return (
    <>
      <div className="App">
        <Header />
        <Router />
        <Footer />
      </div>
    </>
  );
};

export default App;
