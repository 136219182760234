import { mode } from "@chakra-ui/theme-tools";
import { extendTheme } from "@chakra-ui/react";

const styles = {
  global: (props: any) => ({
    body: {
      //   color: mode("gray.800", "whiteAlpha.900")(props),
      //   bg: mode("gray.100", "#141214")(props),
      bg: "white",
      color: "gray",
      fontFamily: "body",
    },
    heading: {
      fontFamily: "heading",
    },
    a: {
      color: "messenger.500",
      _hover: {
        textDecoration: "underline",
      },
    },
  }),
};

const fonts = {
  heading: "Roboto Mono, Monaco, monospace",
  body: "Roboto Mono, Monaco, monospace",
};

const components = {
  Drawer: {
    // setup light/dark mode component defaults
    baseStyle: (props: any) => ({
      dialog: {
        bg: mode("white", "#141214")(props),
      },
    }),
  },
  Link: {
    baseStyle: {
      color: "messenger.500",
      _hover: {
        textDecoration: "underline",
      },
    },
  },
};

const theme = extendTheme({
  fonts,
  components,
  styles,
});

export default theme;
