import { Button, Stack, Text } from "@chakra-ui/react";

import Infomation from "./Information";

import { URL_PDF, COLOR_PURPLE } from "../../constants";

const Home = () => {
  return (
    <>
      <Infomation />
      <Stack align={"flex-center"}>
        <Button
          cursor={"pointer"}
          as={"a"}
          href={URL_PDF}
          target="_blank"
          bg={COLOR_PURPLE}
          fontSize={"lg"}
          fontWeight={600}
          color={"white"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          mt="4"
          mr="4"
        >
          <Text ml={4}>Read Book</Text>
        </Button>
      </Stack>
      <p>You can do this, I believe in you. 👽 </p>
    </>
  );
};

export default Home;
